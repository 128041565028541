import React, { useEffect, useState } from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField, Grid, Button } from "@mui/material";
import { URLS } from "../../helpers/URLS";
import useAxios from "../../helpers/Hooks/useAxios";
import Accordion from 'react-bootstrap/Accordion';
import SkeletonLoader from "../../loaders/Skeleton";
import NoData from "../../loaders/NoData";
import AddStatus from "./AddStatus";
import dayjs from 'dayjs';
import moment from "moment";
import { toast } from 'react-toastify';
import '../../App.scss'

const statusObj = {
    projectID: "",
    activityID: "",
    hours: 0,
    status: "",
    description: ""
}

const DailyStatus = () => {

    const { fetchData } = useAxios();
    const [loadSkeleton, setLoadSkeleton] = useState(true);
    const [isStatusModel, setIsStatusModel] = useState(false);
    const [isNodata, setNodata] = useState(false);
    const [searchParameters, setSearchParameters] = useState({
        startDate: new Date(moment().subtract(10, 'days').format('YYYY-MM-DD')),
        endDate: new Date()
    });
    const [activitiesList, setActivitiesList] = useState([]);
    const [assignedProjectList, setAssignedProjectList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [statusPayload, setStatusPayload] = useState({
        date: dayjs(new Date()),
        dailystatusID: "",
        List: [{ ...statusObj }]
    })

    const getActivitiesList = async () => {
        try {
            const resp = await fetchData({ url: URLS.getActivities, method: 'get' });
            if (resp.status === 200) {
                setActivitiesList(resp.data)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getAssignedProjectsList = async () => {
        try {
            const resp = await fetchData({ url: URLS.getAssignedProjects, method: 'get' });
            if (resp.status === 200) {
                setAssignedProjectList(resp.data)
            }
        } catch (error) {
            console.log(error);
        }
    }
    const getDailyStatusdownload = async () => {
        try {          
            const resp = await fetchData({ url: `${URLS.getDailyStatusdownload}?startDate=${searchParameters.startDate}&endDate=${searchParameters.endDate}`, method: 'get' });
            if (resp.status === 200) {
                window.open(`${URLS.fileDownloadServer}${resp.data.location}/${resp.data.fileName}`)
                // setStatusList(resp.data)
                setNodata(true)
            } 
            else 
            {
                toast.error(resp.message, 'error')
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getDailyStatus = async () => {
        try {
            setLoadSkeleton(true);
            const resp = await fetchData({ url: `${URLS.getDailyStatus}?startDate=${searchParameters.startDate}&endDate=${searchParameters.endDate}`, method: 'get' });
            if (resp.status === 200) {
                setStatusList(resp.data);
                setLoadSkeleton(false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const modelHandle = () => {
        setIsStatusModel(!isStatusModel)
    }

    useEffect(() => {
        getActivitiesList();
        getAssignedProjectsList();
        getDailyStatus()
    }, [])

    return (
        <div className=''>
            <div className="d-flex">
                <h4 className="title">My Work Reports</h4>
                <div className="ms-auto">
                    <Button type="button" variant="contained" onClick={(e) => {
                        setStatusPayload({
                            date: new Date(),
                            dailystatusID: "",
                            List: [{ ...statusObj }]
                        })
                        modelHandle();
                    }} className="btn btn-primary mb-2">Add Status</Button>
                </div>
            </div>
            <div className="bg-wht">
                <div className='resp_status_big mt-4'>
                    {/* <div className="d-flex mx-3 mt-2"> */}
                    <div className="mx-3 mt-2">
                        <div className="w-70">
                            <div className="d-flex pb-3">
                            <Grid container className="pb-3 align-items-center">
                                <Grid item xs={3} sx={{ padding: 2 }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            label="Start Date"
                                            inputFormat="DD/MM/YYYY"
                                            value={new Date(searchParameters.startDate)}
                                            maxDate={new Date()}
                                            onChange={(date) => { setSearchParameters({ ...searchParameters, startDate: date }) }}
                                            renderInput={(params) => <TextField {...params} fullWidth required
                                            />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={3} sx={{ padding: 2 }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            label="End Date"
                                            inputFormat="DD/MM/YYYY"
                                            value={new Date(searchParameters.endDate)}
                                            minDate={new Date(searchParameters.startDate)}
                                            maxDate={new Date()}
                                            onChange={(date) => setSearchParameters({ ...searchParameters, endDate: date })}
                                            renderInput={(params) => <TextField {...params} fullWidth required
                                            />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={2} >
                                <Button variant="contained" onClick={(e) => {
                                    getDailyStatus();
                                }}>Search</Button>
                                 </Grid>
                               <Grid item xs={4} sx={{ textAlign: 'right' }}>
                                <Button
                                disabled={statusList.length?false:true}
                                 variant="contained" onClick={(e) => {
                                    getDailyStatusdownload();
                                }}>Download</Button>
                                 </Grid>
                                 </Grid>
                                {/* <div className='ms-4 mt-3'>
                                    <Button variant="contained" onClick={(e) => {
                                        getDailyStatus();
                                    }}>Search</Button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='resp_status_small'>
                    <div className="d-flex justify-content-center">
                        <div className="">
                            <div className='mb-2'>
                                <Grid item xs={6} sx={{ padding: 2 }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            label="Start Date"
                                            inputFormat="DD/MM/YYYY"
                                            value={new Date(searchParameters.startDate)}
                                            maxDate={new Date()}
                                            onChange={(date) => { setSearchParameters({ ...searchParameters, startDate: date }) }}
                                            renderInput={(params) => <TextField {...params} fullWidth required
                                            />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </div>
                            <div className=''>
                                <Grid item xs={6} sx={{ padding: 2 }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            label="End Date"
                                            inputFormat="DD/MM/YYYY"
                                            value={new Date(searchParameters.endDate)}
                                            minDate={new Date(searchParameters.startDate)}
                                            maxDate={new Date()}
                                            onChange={(date) => setSearchParameters({ ...searchParameters, endDate: date })}
                                            renderInput={(params) => <TextField {...params} fullWidth required
                                            />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </div>
                        </div>
                    </div>
                    <div className='m-2'>
                        <div className='d-flex justify-content-around'>
                            <Button variant="contained" onClick={(e) => {
                                getDailyStatus();
                            }}>Search</Button>
                        </div>
                    </div>
                </div>
                {loadSkeleton ?
                    <SkeletonLoader /> : (statusList.length > 0 ?
                        <Accordion defaultActiveKey="0" className="m-3">
                            {statusList.map((row, index) => <Accordion.Item key={index} eventKey={index}>
                                <Accordion.Header>{moment(row.date).format('DD MMMM YYYY')}</Accordion.Header>
                                <Accordion.Body>
                                    <div className='col-md-12'>
                                        <div className="text-end mb-3">
                                            <Button type="button" variant="contained" onClick={e => {
                                                setStatusPayload({
                                                    date: new Date(row.data[0].date),
                                                    dailystatusID: row.data[0].dailystatusID,
                                                    List: row.data.map(item => {
                                                        return {
                                                            projectID: item.projectID,
                                                            activityID: item.activityID,
                                                            hours: item.hours,
                                                            status: item.status,
                                                            description: item.description
                                                        }
                                                    })
                                                })
                                                modelHandle();
                                            }}>Edit</Button>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Project Name</th>
                                                    <th scope="col">Activity Name</th>
                                                    <th scope="col">Hours</th>
                                                    <th scope="col">Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {row.data.map((s, subIndex) => <tr key={subIndex}>
                                                    <td scope="row">{subIndex + 1}</td>
                                                    <td>{s.project.projectName}</td>
                                                    <td>{s.Activity.activityName}</td>
                                                    <td>{s.hours}</td>
                                                    <td>{s.description}</td>
                                                </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>)}
                        </Accordion>
                        : <NoData />
                    )
                }
            </div>
            <AddStatus isStatusModel={isStatusModel} modelHandle={modelHandle} statusPayload={statusPayload} setStatusPayload={setStatusPayload} statusObj={statusObj} assignedProjectList={assignedProjectList} activitiesList={activitiesList} getDailyStatus={getDailyStatus} />
        </div>
    );
}

export default DailyStatus;
